import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useMemo, useEffect, Fragment } from 'react'

// import './i18n';
import '../config/i18n';

import configureUiStore from '../hook-store/ui-store';
import configureBucketObjectStore from '../hook-store/bucket-object-store';
import configureAdminStore from '../hook-store/adimin-store';
import configureStripeDataStore from "../hook-store/stripe-data-store";
import configureImageEditorStore from '../hook-store/image-editor-store';
import configureImageResizerStore from '../hook-store/image-resizer-store';
import configureLamaCleanerStore from '../hook-store/lama-cleaner-store';
import configureServiceBucketsStore from '../hook-store/service-buckets-store';
import configureObjectMetadataStore from '../hook-store/object-metadata-store';
import configureDrawDreamerStore from '../hook-store/draw-dreamer-store';

configureUiStore();
configureBucketObjectStore();
configureAdminStore();
configureStripeDataStore();
configureImageEditorStore();
configureLamaCleanerStore();
configureServiceBucketsStore();
configureObjectMetadataStore();
configureImageResizerStore();
configureDrawDreamerStore();


const TldreamEditor = dynamic(() => import('~components/TldreamEditor/TldreamEditor'), { ssr: false }) as any


const Home = () => {
  const { query } = useRouter()
  const isExportMode = useMemo(() => 'exportMode' in query, [query])

  return (
    <>
      <Head>
          {/* <title>tldream</title> */}
          <title>Kura Draw Dreamer</title>
      </Head>
      <TldreamEditor id="home" showUI={!isExportMode} />
    </>

  );
}

export default Home
