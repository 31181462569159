import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_SHOWPRIVACYPOLICY: (state, payload) => {
      return { 
        drawDreamerStore : {
          ...state.drawDreamerStore,
          showPrivacypolicy: payload, 
        }
      };
    },
    SET_SHOWTERMSOFUSE: (state, payload) => {
      return { 
        drawDreamerStore : {
          ...state.drawDreamerStore,
          showTermsofuse: payload, 
        }
      };
    },
    SET_SERVERPROCESSING: (state, payload) => {
      return { 
        drawDreamerStore : {
          ...state.drawDreamerStore,
          serverProcessing: payload, 
        }
      };
    },
    SET_ISUSERRUNNING: (state, payload) => {
      return { 
        drawDreamerStore : {
          ...state.drawDreamerStore,
          isUserRunning: payload, 
        }
      };
    },
    SET_PROMPTSERVERPROCESSING: (state, payload) => {
      return { 
        drawDreamerStore : {
          ...state.drawDreamerStore,
          promptServerProcessing: payload, 
        }
      };
    },
    SET_ISPROMPTUSERRUNNING: (state, payload) => {
      return { 
        drawDreamerStore : {
          ...state.drawDreamerStore,
          isPromptUserRunning: payload, 
        }
      };
    },
  };

  initStore(actions, {
    drawDreamerStore: {
      showPrivacypolicy: false,
      showTermsofuse: false,
      serverProcessing: false,
      isUserRunning: false,
      promptServerProcessing: false,
      isPromptUserRunning: false,
    }
  });
};

export default configureStore;
